/* Override variables in the following import from Choices.js */
$choices-primary-color: $color-white;
$choices-bg-color: $color-white;

/* Choices.js styling */
@import '~choices.js/src/styles/choices.scss';

.choices[data-type*='select-multiple'] {
  .choices__inner {
    background: #fff no-repeat center right 10px url('../../../images/icons/arrow-alt--down.svg');
    background-size: 12px;
    padding-right: 17.5px;
  }

  .choices__button {
    background-image: $choices-icon-cross-inverse;
    border-left-color: $color-grey;
  }
  
  .choices__list--multiple .choices__item {
    color: $color-black;
    border-color: $color-grey;
    border-radius: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }
  
  .choices__list--dropdown {
    .choices__item--selectable.is-highlighted {
      background-color: $color-grey--lighter;
    }

    height: 0;

    &.is-active {
      height: auto;
    }
  }
}
