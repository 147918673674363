.c-pricing-option {
  margin-bottom: 2rem;

  @include media-breakpoint-up(sm) {
    height: 100%;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: row; // IE 11
  }
}

.c-pricing-option--secondary {
  @include media-breakpoint-up(sm) {
    height: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .c-pricing-option__content {
      min-height: 195.5px;
    }
 }
}

.c-pricing-option.is-selected {
  .c-pricing-option__content {
    outline: 2px solid $color-green;
  }

  .c-pricing-option__cta {
    display: none;
  }

  .c-pricing-option__chosen {
    display: block;
  }
}

.c-pricing-option__content {
  position: relative;
  background: $color-white;
  padding: 1.5rem 2rem 2rem;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    padding: 1.9rem 2.4rem;
    flex: 1;
  }

  &.c-pricing-option__content--corporate {
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }
  }
}

.c-pricing-option__name,
.c-pricing-option__specific,
.c-pricing-option-condition,
.c-pricing-option__price,
.c-pricing-option__chosen {
  text-align: left;
}

@include media-breakpoint-up(sm) {
  .c-pricing-option__name,
  .c-pricing-option__price {
    text-align: center;
  }
}

.c-pricing-option--secondary {
  .c-pricing-option__details .c-pricing-option__price {
    display: none;
  }

  &.is-selected .c-pricing-option__chosen {
    text-align: center
  }

  .c-pricing-option__star_explanation {
    font-size: 1.5rem;
    font-style: italic;
  }

  @include media-breakpoint-up(sm) {

    .c-pricing-option__left-col {
      display: flex;
      flex-direction: column;
    }

    .c-pricing-option__specific,
    .c-pricing-option-condition,
    .c-pricing-option__price,
    .c-pricing-option__name {
      font-size: 2.5rem;
      line-height: 3rem;
      margin-top: 0;
      margin-bottom: 1rem;
      text-align: left;
    }

    .c-pricing-option__name {
      margin-bottom: 0.75rem;

      &.c-pricing-option__name--centered {
        text-align: center;
      }
    }

    .c-pricing-option__price {
      font-size: 3rem;
      margin: auto 0;
      text-align: center;
    }

    .c-pricing-option__includes {
      margin: 0;
    }

    .c-pricing-option__include {
      margin-bottom: 0;
    }

    .c-pricing-option__content {
      padding: 1.9rem 2.4rem;

      .c-pricing-option__price {
        display: none;
      }
    }

    .c-pricing-option__details {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 14rem;

      .c-pricing-option__price {
        display: block;
      }
    }

    .c-pricing-option__footer {
      bottom: 0;
    }

    .c-price__text {
      display: block;
      @include media-breakpoint-up(sm) {
        font-size: 2.4rem;
      }
    }

    .c-price__suffix {
      font-size: 2rem;
    }

    .c-pricing-option__star_explanation {
      margin-top: auto;
      padding-top: 1rem;
    }

    .c-price {
      position: relative;
      line-height: 2.5rem;
    }
  }

  .c-price__from {
    text-decoration: line-through;
    color: $color-grey--lighter;
    font-weight: 400;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 11px;
      left: -9px;
      font-size: 2rem;
    }
    
    @include media-breakpoint-up(md) {
      top: 1.5rem;
      left: 1rem;   
    }
  }

}

.c-pricing-option__name {
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 1rem;
  line-height: 1.3;
  
  @include media-breakpoint-up(sm) {
    font-size: 3.5rem;
    margin: 3rem 0 6rem;
    line-height: 5rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }
}

.c-pricing-option__price {
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 7.3rem;
  }
}

.c-price__text {
  letter-spacing: 1.02px;
}

.c-pricing-option__specific {
  height: 3rem;
  margin-bottom: 2rem;
}

.c-pricing-option__star_explanation {
  margin-bottom: 1.5rem;
}

.c-pricing-option__label {
}

.c-pricing-option__includes {
  @include media-breakpoint-up(sm) {
    margin: 0 auto 2rem;
  }
}

.c-pricing-option__include {
  @extend p;
  position: relative;
  margin-bottom: 1rem;
  padding: 0 0 0.5rem 3rem;
  font-size: 1.5rem;
  line-height: 2rem;

  &:before {
    content: ' ';
    background: url('../../../images/binnenlandsbestuur/checkmark.svg') no-repeat;
    background-size: 16px 16px; // using rem looks different in IE11
    position: absolute;
    top: 0.4rem;
    left: 0rem;
    height: 1.6rem;
    width: 1.6rem;
  }

  @include media-breakpoint-up(sm) {
    &:before {
      top: 0.2rem;
    }
  }
}

.c-pricing-option__cta {
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.c-pricing-option__footer {
  margin-top: auto;
}

.c-pricing-option__includes {
  margin-bottom: 1.5rem;
}

.c-pricing-option__includes {
  margin-bottom: 1.5rem;
}

.c-pricing-option-condition {
  margin: 1rem auto 2rem;
  font-family: $ff-monospace;
  color: $color-grey--light;
  font-size: map_get($fs--small, xs);
  line-height: map_get($lh--small, xs);

  @include media-breakpoint-up(sm) {
    font-size: map_get($fs--small, sm);
    line-height: map_get($lh--small, sm);
  }
}

.c-pricing-option__chosen {
  display: none;
  line-height: 4.5rem;
  color: $color-green;
}

