.c-toggle-slider {
    display: flex;
    width: 30rem;
    margin: 0 auto;

    input {
        opacity: 0;
        height: 0;
        width: 0;
    }

    label {
        display: flex;
        margin-bottom: 0;
        cursor: pointer;
    }
}

.c-toggle-slider--pill {
    input {
        &:checked + label {
            .c-toggle-slider__dot {
                transform: translate(12px, -50%);
            }

            span:first-of-type {
                color: $color-grey;
            }

            span:last-of-type {
                color: $color-black;
                font-weight: bold;
            }
        }

        &:not(checked) + label {
            span:first-of-type {
                color: $color-black;
                font-weight: bold;
            }

            span:last-of-type {
                color: $color-grey;
            }
        }
    }

    span {
        font-size: 1.5rem;
        transition: color 300ms;
        text-align: center;

        &::after {
            content: attr(title);
            display: block;
            font-weight: bold;
            height: 1px;
            color: transparent;
            overflow: hidden;
            visibility: hidden;
        }
    }

    .c-toggle-slider__slider {
        position: relative;
        width: 3.2rem;
        height: 1.6rem;
        background-color: $color-grey--lighter;
        border-radius: 2rem;
        margin: 0 1.2rem;
    }

    .c-toggle-slider__dot {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 300ms ease;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        background-color: $color-blue;
    }

    label {
        align-items: center;
    }

    &-xl {
        $multiplier: 2;

        span {
            font-size: 1.5rem * $multiplier;
        }

        .c-toggle-slider__slider {
            width: 3.2rem * $multiplier;
            height: 1.6rem * $multiplier;
            margin: 0 1.2rem * $multiplier;
        }

        .c-toggle-slider__dot {
            height: 2rem * $multiplier;
            width: 2rem * $multiplier;
        }

        input:checked + label .c-toggle-slider__dot {
            transform: translate(12px * $multiplier, -50%);
        }
    }
}

$color-selected: $color-white;
$color-bg-selected: $color-blue;
$color-not-selected: $color-blue;
$color-bg-hover: $color-white;
$color-border: $color-blue;

.c-toggle-slider--box {
    position: relative;

    input {
        &:checked + label {
            span:first-of-type {
                color: $color-not-selected;

                &:hover {
                    background-color: $color-bg-hover;
                }
            }

            span:last-of-type {
                color: $color-selected;
            }

            .c-toggle-slider__slider {
                transform: translateX(100%);
            }
        }

        &:not(:checked) + label {
            span:first-of-type {
                color: $color-selected;
            }

            span:last-of-type {
                color: $color-not-selected;

                &:hover {
                    background-color: $color-bg-hover;
                }
            }
        }
    }

    label {
        width: 30rem;
        height: 4rem;
        border: 2px solid $color-border;
        font-family: $ff-sans-serif;
        font-size: 1.8rem;
        font-weight: 400;
        text-align: center;
        transition: background-color 300ms;
    }

    span {
        width: 50%;
        height: 3.6rem;
        line-height: 3.6rem;
        z-index: 1;
        transition: all 300ms ease;
    }

    .c-toggle-slider__slider {
        position: absolute;
        z-index: 0;
        height: 100%;
        width: calc(50% - 2px);
        top: 0;
        background-color: $color-bg-selected;
        transition: transform 300ms ease;
    }
}
