.c-paragraph .c-paragraph__content {
  font-size: 1.7rem;
  font-weight: bold;
  letter-spacing: 1.02px;

  &.c-paragraph__content--normal {
    font-weight: normal;
  }
}

.c-paragraph--drop-cap {
  p:first-of-type:first-letter {
    font-family: $ff-sans-serif;
    font-weight: 300;
    float: left;
    margin-right: 10px;
    font-size: map_get($fs--first-letter, xs);
    line-height: map_get($lh--first-letter, xs);

    @include media-breakpoint-up(sm) {
      margin-right: 15px;
      font-size: map_get($fs--first-letter, sm);
      line-height: map_get($lh--first-letter, sm);
    }
  }
}

.c-paragraph__title {
  margin-bottom: 5px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 10px;
  }
}

.c-paragraph__content {
  p {
    margin-bottom: 25px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 30px;
    }
  }

  p.is-faded {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75%;
      background: $gradient-white-transparant;
    }
  }

  ul, ol {
    @extend p;
    padding-left: 4rem;

    ul {
      list-style-type: circle;
    }

    ol {
      list-style-type: decimal;
    }
  }

  ul {
    list-style-type: disc;
  }

  table {
    @extend p;
    width: 100%;
    border-collapse: collapse;
  }

  table, th, td {
    border: 1px solid $color-grey--lighter;
  }

  th, td {
    padding: 0 1rem;
  }
}
