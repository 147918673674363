.c-order-title {
  margin: 5rem 0 2rem;
}

.c-order-title--counter {
  &::before {
    counter-increment: order-steps;
    content: counter(order-steps) '. ';
  }
}
