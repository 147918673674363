.c-subscription-overview {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 3rem;
}

.c-subscription-overview__group-toggle {
    margin: 6rem auto;
}

.c-subscription-overview__footnote {
    margin-top: auto;
    margin-bottom: 2rem;
    color: $color-grey;
    text-align: center;
    font-style: italic;
}
