.c-step-indicator {
  border-bottom: 1px solid $color-grey--light;
  background: $color-white;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
}

.c-step-indicator__content {
  display: inline-flex;
  width: 100%;
  padding: 1rem 0;
  counter-reset: step-indicator;

  @include media-breakpoint-up(sm) {
    padding: 2rem 0;
  }
}

.c-step-indicator__step {

  @include media-breakpoint-up(sm) {
    // margin: 0 1.5rem;
  }
}
