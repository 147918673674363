.c-signup-header {

    h1 {
        font-size: 2.4rem;
        line-height: 3.2rem;
        margin-bottom: 2.4rem;

        @include media-breakpoint-up(sm) {
            font-size: 3.6rem;
            line-height: 4.8rem;
            margin-bottom: 3.1rem;
        }
    }

    p {
        &:first-of-type {
            margin-bottom: 0.8rem;
        }

        @include media-breakpoint-up(sm) {
            font-size: 2.6rem;
            line-height: 2.5rem;
            &:first-of-type {
                margin-bottom: 1.5rem;
            }
        }
    }
}
