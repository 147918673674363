.c-page {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-page__body {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;
  flex: auto;
  background: $color-grey--lightest;
  padding-top: 1.6rem;

  @include media-breakpoint-up(sm) {
    padding-top: 4.5rem;
  }
}
