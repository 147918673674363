.c-step {
  color: $color-black;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
    line-height: 1;
  }

  &:hover {
    color: $color-black;
  }

  &.is-uncompleted {
    color: $color-grey--light;
    pointer-events: none;
  }

  &.is-completed {
    color: $color-grey--light;
  }

  &.is-active {
    .c-step__text,
    .c-step__icon:before {
      font-weight: bold;
    }
  }

  &:not(.is-active) {
    .c-step__text {
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
  }
}

.c-step__icon {
  display: inline-block;
  margin-right: 1rem;
  width: 26px;
  height: 26px;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 50%;
  text-align: center;

  @include media-breakpoint-up(sm) {
    border: none;
    width: auto;
    height: auto;
    margin-right: 0;
  }

  &:before {
    counter-increment: step-indicator;
    content: counter(step-indicator);
    position: relative;
    bottom: -2px;
    font-size: 1.2rem;
    
    @include media-breakpoint-up(sm) {
      content: counter(step-indicator) ". ";
      white-space: pre;
      bottom: 0;
      font-size: 1.4rem;
    }
  }
}

.c-step__text {
  display: inline-block;
  margin-right: 2rem;
  align-items: center;
  justify-content: center;
}

.c-step-indicator__step:last-child {
  .c-step__text {
    margin-right: 0;
  }
}
