.c-membership-type {
  margin-bottom: 2rem;
  width: 100%;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    -ms-flex-direction: row; // IE 11
  }
}

.c-membership-type.is-selected {
  .c-membership-type__cta {
    display: none;
  }

  .c-membership-type__name {
    font-size: 1.7rem;
    letter-spacing: 1.02px;
    margin-bottom: 0;
  }

  .c-membership-type__seats {
      display: none;
  }

  .c-membership-type__price {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    width: 100vw;
    margin: 0 -1.5rem 2rem;
    
    .c-membership-type__content {
      padding: 3.2rem 1.5rem;
      line-height: 1;
    }
  }
}

.c-membership-type__content {
  background: $color-white;
  padding: 1.5rem 3rem 2rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include media-breakpoint-up(sm) {
    padding: 2rem;
    flex: 1;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    min-height: 100px;
 }
}

.c-membership-type__description {
  width: 100%;
}

.c-membership-type__name {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  transition: font-size 400ms ease;

  @include media-breakpoint-up(sm) {
    font-size: 2.7rem;
  }
}

.c-membership-type__seats {
    margin-bottom: 1rem;
    color: $color-grey;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 400;
    
    @include media-breakpoint-up(sm) {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        font-size: 2.5rem;
    }
}

.c-membership-type__price {
  font-size: 2rem;
  @include media-breakpoint-up(sm) {
    font-size: 2.7rem;
  }
}

.c-membership-type__specific {
  height: 3rem;
  margin-bottom: 2rem;
}

.c-membership-type__label {
}

.c-membership-type__includes {
  display: none;
}

.c-membership-type__cta {
  width: 100%;
}

.c-membership-type__footer {
}

.c-membership-type__chosen {
  display: none;
  line-height: 4.5rem;
  color: $color-blue;
}

.c-membership-type__footnote {
    font-size: 1.5rem;
    font-style: italic;
}
