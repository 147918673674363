.c-subscription-cards__group {
    position: relative;
    margin: 3rem 0;
    padding: 0 15px;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    &.c-subscription-cards__group--corporate {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }
}
