/**
* @file
* All colors used on the website are defined here
*/

/* Base colors */
$color-black: #111111;
$color-white: #ffffff;
$color-yellow: #ffdd02;
$color-blue: #003964;
$color-green: #01967f;
$color-red: #e90027;
$color-orange: #e95a00;

/* Secondary colors */
$color-blue--dark: #005085;
$color-purple: #9265a8;
$color-pink: #feebee;
$color-orange--light: lighten($color-orange, 15%);

/* Grey colors */
$color-grey: #666666;
$color-grey--light: #b3b3b3;
$color-grey--lighter: #979797;
$color-grey--lightest: #e8e8e8;
$color-grey--lightest-extra: #efefef;
$color-grey--dark: #1a1a1a;

/* Gradients */
$gradient-yellow-red: linear-gradient(90deg, $color-yellow, $color-red);
$gradient-yellow-blue: linear-gradient(90deg, $color-yellow, $color-blue);
$gradient-yellow-green: linear-gradient(90deg, $color-yellow, $color-green);
$gradient-yellow-purple: linear-gradient(90deg, $color-yellow, $color-purple);
$gradient-white-transparant: linear-gradient(-180deg, rgba($color-white, 0) 0%, $color-white 100%);
$gradient-dark-grey-black: linear-gradient(-90deg, #262626 1%, #000000 100%);
$gradient-transparent-grey: linear-gradient(90deg, rgba(237, 237, 237, 0) 0%, #ededed 100%);

/* vendor colors */
$color-social--facebook: #3b5998;
$color-social--twitter: #55acee;
$color-social--linkedin: #007bb5;

$opacity-darken: 10%;
$opacity-fadein: 5%;
$opacity-disabled: 0.4;
$opacity-placeholder: 0.6;
$opacity-inactive: 0.65;

$color-primary-grey: #999999;
$color-primary-yellow: #f8ae4c;
$color-primary-blue-light: #7fcff5;
$color-primary-green: #90c369;
$color-primary-red: #e8412a;
$color-primary-pink: #cb7db3;
$color-primary-blue-dark: #2d5d9f;

$color-input-border: #c1c3c4;
$color-input-border--focus: $color-blue;
$color-input-border--valid: $color-primary-green;
$color-input-border--invalid: $color-primary-red;

$color-checkbox: #0080b8;

/* Parsley colors */
$field-success-color: $color-green;
$field-error-color: $color-red;
