.c-enrich-disclaimer {

    p {
        &:first-of-type {
            margin-bottom: 0.8rem;
        }

        @include media-breakpoint-up(sm) {
            font-size: 1.6rem;
            line-height: 2.0rem;
            &:first-of-type {
                margin-bottom: 1.5rem;
            }
        }
    }
}
