/**
* @file
* The main css file,
* it should only import partials and
* never include any code
*/

/* Styling from @sijthoffmedia/smg-assets */
@import '@sijthoffmedia/smg-assets/scss/binnenlandsbestuur/_style.scss';

/*
* Basics styling
* These files should contain the styling for
* general layout, input elements, buttons, etc.
*/
@import "binnenlandsbestuur/basics/grid";
@import "binnenlandsbestuur/basics/typography";
@import "binnenlandsbestuur/basics/iconfont";
@import "binnenlandsbestuur/basics/button";
@import "binnenlandsbestuur/basics/block";
@import "binnenlandsbestuur/basics/form-label";
@import "binnenlandsbestuur/basics/form-help";
@import "binnenlandsbestuur/basics/form-control";
@import "binnenlandsbestuur/basics/checkbox";
@import "binnenlandsbestuur/basics/radio-button";
@import "binnenlandsbestuur/basics/select";
@import "binnenlandsbestuur/basics/tab-group";
@import "binnenlandsbestuur/basics/display";
@import "binnenlandsbestuur/basics/link";
@import "binnenlandsbestuur/basics/form-buttons";
@import "binnenlandsbestuur/basics/form-row";
@import "binnenlandsbestuur/basics/page";
@import "binnenlandsbestuur/basics/form-validation";
@import "binnenlandsbestuur/basics/page-title";
@import "binnenlandsbestuur/basics/toggle";
@import "adformatie/basics/ie11";
@import "binnenlandsbestuur/basics/faq";

/*
* Components styling
* The files should contain the styling for
* components like header, footer, article, etc.
*/

@import "common/universal";
@import "common/ticket";
@import "common/site-footer";

@import "binnenlandsbestuur/components/date-select";
@import "binnenlandsbestuur/components/membership-type-block";
@import "binnenlandsbestuur/components/membership-type";
@import "binnenlandsbestuur/components/site-header";
@import "binnenlandsbestuur/components/site-branding";
@import "binnenlandsbestuur/components/page-header";
@import "binnenlandsbestuur/components/signup-header";
@import "binnenlandsbestuur/components/signup-title";
@import "binnenlandsbestuur/components/enrichment-disclaimer";
@import "binnenlandsbestuur/components/register-footnote";
@import "binnenlandsbestuur/components/register-title";
@import "binnenlandsbestuur/components/request-form";
@import "binnenlandsbestuur/components/form";
@import "binnenlandsbestuur/components/step-indicator";
@import "binnenlandsbestuur/components/step";
@import "binnenlandsbestuur/components/pricing-block";
@import "binnenlandsbestuur/components/pricing-option";
@import "binnenlandsbestuur/components/pricing-label";
@import "binnenlandsbestuur/components/price";
@import "binnenlandsbestuur/components/dashboard";
@import "binnenlandsbestuur/components/account-link";
@import "binnenlandsbestuur/components/postcode-check";
@import "binnenlandsbestuur/components/paragraph";
@import "binnenlandsbestuur/components/banner";
@import "binnenlandsbestuur/components/flash-messages";
@import "binnenlandsbestuur/components/ticket";
@import "binnenlandsbestuur/components/order-title";
@import "binnenlandsbestuur/components/multi-select";
@import "binnenlandsbestuur/components/info-page";
@import "binnenlandsbestuur/components/subscription-info";
@import "binnenlandsbestuur/components/criteria";
@import "binnenlandsbestuur/components/date-select";
@import "binnenlandsbestuur/components/password-reset";
@import "binnenlandsbestuur/components/subscription-cards";
@import "binnenlandsbestuur/components/subscription-card";
@import "binnenlandsbestuur/components/toggle-slider";
@import "binnenlandsbestuur/components/subscription-overview";
@import "binnenlandsbestuur/components/group-label";
@import "binnenlandsbestuur/components/form-footer";
@import "binnenlandsbestuur/components/select-account";

/**
* Theme styling
* This file contains styling on page level in which nested elements
* have specific styles according to their parents.
* Be extra careful with specificity in here!
 */
@import "binnenlandsbestuur/theme/default";

/* Use this file for hotfixes and refactor later */
@import "binnenlandsbestuur/shame";
