.c-faq {
  h1 {
    text-align: center;
    font-family: "Brown Pro", sans-serif;
    margin-bottom: 5px;
  }

  hr {
    margin: 0;
    color: #003964;
  }

  ul {
    list-style-type: circle;
  }

  &__group {
    position: relative;
    padding: 30px 0;
    cursor: pointer;
  }

  &__question {
    text-align: left;
    font-weight: bold;
    user-select: none;
    font-size: 2.5rem;

    &:after {
      content: '+';
      position: absolute;
      top: 3.5rem;
      font-size: 4rem;
      right: 0;
      font-weight: 300;
      line-height: 0;
      color: #003964;
    }
  }

  &__answer {
    cursor: default;
    text-align: left;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s, margin-top 0.3s;
    font-size: 1.5rem;
    padding: 0 0 0.5rem 3rem;
  }

  .c-faq__group--open {
    .c-faq__answer {
      margin-top: 10px;
      max-height: 500px;
    }
    
    .c-faq__question:after {
      content: '–';
      right: 0.15rem;
    }
  }
}
