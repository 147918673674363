.c-price {
  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
    letter-spacing: 1.02px;
  }
}

.c-pricing-option {
  .c-price {
    font-weight: 600;

    @include media-breakpoint-up(sm) {
      font-size: 3.1rem;
    }
  }
}
