/**
* @file
* Basic styling for input fields & text areas
*
* @example
* <input type="text" class="c-form-control" />
* <textarea type="text" class="c-form-control"></textarea>
 */

::placeholder {
  color: $color-grey--light;
}

.c-form-control {
  display: block;
  width: 100%;
  background: #fff;
  line-height: $lh--form;
  padding: 13px 15px 12px;
  border: solid $color-input-border 1px;
  border-radius: 3px;

  &:focus,
  &.is-active {
    border-color: $color-input-border--focus;
  }

  &:disabled,
  &.is-disabled {
    border-color: $color-grey--light;
  }

  &:read-only {
    outline: none;
    color: $color-grey;
    background-color: $color-grey--lightest-extra;
  }

  &:read-only:focus {
    border-color: $color-input-border;
  }

  &--block {
    display: block;
    width: 100%;
  }
}
