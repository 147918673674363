/**
* @file
* Basic styling for form labels
*
* @example
* <p class="c-form__help">Help text for form element</p>
 */

.c-form__help {
  font-size: $fs--help;
  margin-bottom: 10px;
}
