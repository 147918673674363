.c-signup-title {
  display: flex;
  margin: 3.2rem 0 1.1rem;
  font-size: 2rem;
  line-height: 2.5rem;

  @include media-breakpoint-up(sm) {
    margin: 3.7rem 0 2.4rem;
  }
}

.c-signup-title--counter {
  &::before {
    counter-increment: signup-steps;
    content: counter(signup-steps) '. ';
  }
}

.c-signup-title__change-button {
  margin-left: auto;
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-decoration: underline;
  cursor: pointer;
}
