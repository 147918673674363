/**
* @file
* Styling for the header component
*
* @example
* <header class="c-site-header">
*   <div class="c-site-header__body">
*     <div class="c-site-header__branding">...</div>
*   </div>
* </header>
*
 */

.c-site-header {
  border-bottom: 1px solid $color-grey--light;
  background: $color-white;

  &:before {
    content: '';
    display: block;
    height: 6px;
    background: $gradient-yellow-red;
  }
}

.c-site-header__container {
  @include media-breakpoint-up(lg) {
    max-width: 1380px;
  }
}

.c-site-header__body {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-up(sm) {
    padding: 1.5rem 0;
  }
}

.c-site-header__back,
.c-site-header__help {
  display: block;
  text-transform: capitalize;

  @include media-breakpoint-up(sm) {
    text-transform: none;
  }
}

.c-site-header__back {
  left: 0;
}

.c-site-header__help {
  right: 0;
}
