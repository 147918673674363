/**
* @file
* The standard styling for all buttons in the theme
*
* @example
* <button class="c-button">Call to action</button>
*/

@mixin form-button {
  display: inline-block;
  cursor: pointer;
  background-color: $color-blue;
  font-family: $ff-sans-serif;
  color: $color-white;
  padding: 7px 20px;
  border: solid transparent 2px;
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 2.4rem;

  &:hover {
    color: $color-white;
    background-color: darken($color-blue, $opacity-darken);
  }

  &--go::after {
    content: ' →';
  }
}

.c-button {
  @include form-button();
  &--secondary {
    border-color: $color-blue;
    background-color: $color-white;
    color: $color-blue;

    &:hover {
      color: $color-blue;
      background-color: mix($color-blue, $color-white, $opacity-fadein);
    }
  }
}

[class*="c-button"][disabled],
[class*="c-button"]:disabled,
[class*="c-button"].is-disabled {
  opacity: $opacity-disabled;
  cursor: default;
  pointer-events: none;
}

.c-button--linkedin {
  margin-top: 2.4rem;
}
