.c-ticket.c-ticket--no-top-padding {
  padding-top: 0;
}

.c-ticket {
  @include media-breakpoint-up(md) {
    margin: 0;
    padding-top: 14.4rem;
  }
}

.c-ticket--expanded .c-ticket__summary {
  border-top: none;
}

.c-ticket__details {
  @include media-breakpoint-up (md) {
    border: 1px solid $color-grey--lighter;
  }
}

.c-ticket__body {
  background: $color-white;
  border: none;
  @include media-breakpoint-down(xs) {
    padding: 2rem 1.5rem;
  }
}

.c-ticket__body:last-of-type:not(:first-of-type) {
  background-color: $color-grey--lightest-extra;
}

.c-ticket__subscription_include {
  position: relative;
  padding-left: 2rem;
  padding-bottom: 1rem;
  font-size: 1.7rem;

  &:before {
    content: ' ';
    background: url('../../../images/binnenlandsbestuur/checkmark.svg') no-repeat;
    background-size: 1.2rem 1.2rem;
    position: absolute;
    left: 0rem;
    top: 0.4rem;
    height: 1.2rem;
    width: 1.2rem;
  }
}

.c-ticket__options_header,
.c-ticket__subscription_include {
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-bottom: 0;
}

.c-ticket--align-top {
  padding-top: 0;

  .c-signup-title {
    margin-top: 0;
  }
}
