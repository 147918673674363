.c-subscription-card {
    width: 100%;
    margin-bottom: 20px;
    
    &:last-child {
        margin-bottom: 0;
    }
    
    @include media-breakpoint-up(md) {
        width: 33.33333%;
        margin-right: 30px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }
}
