/**
* @file
* Radio button styling
*
* @example
* <span class="c-radio-button">
*  <input type="radio" class="c-radio-button__input" name="radios" id="radios-1" checked />
*  <label class="c-radio-button__label" for="radios-1">Yes</label>
* </span>
* <span class="c-radio-button">
*  <input type="radio" class="c-radio-button__input" name="radios" id="radios-2" />
*  <label class="c-radio-button__label" for="radios-2">No</label>
* </span>
 */

.c-radio-button {
  display: block;
  margin-bottom: 1rem;
}

.c-radio-button__input {
  @extend .c-checkbox__input;
}

.c-radio-button__label {
  @extend .c-checkbox__label;

  &:before,
  &:after {
    border-radius: 50%;
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 6px;
    height: 6px;
    background: $color-checkbox;
    top: 6px;
    left: 6px;
    opacity: 0;
  }
}

.c-radio-button__input:checked + .c-radio-button__label {
  &:before {
    background: $color-white;
    content: '';
  }

  &:after {
    opacity: 1;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
  }
}
