.c-criteria {
  line-height: 2em;
  margin: 1em 0 2em 0;

  ul {
    margin-left: 3em;

    li {
      list-style: disc;
    }
  }

  a {
    margin: 0.5em 0;
  }
}
