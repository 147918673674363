/**
* @file
* Use this file for hotfixes only and refactor later
 */

.c-password-notice {
    margin-top: 1.5rem;
    padding: 1rem 2rem;
    border: 1px solid $color-red;
    background: lighten($color-red, 50);

    p {
        font-size: 1.7rem;
        line-height: 2.5rem;
    }

    &.c-password-notice--login {
        margin-bottom: 1.5rem;
    }
}
