/**
* @file
* Fonts and font variables should be defined here
*/
@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-black.eot');
    src:
        url('../../fonts/wigrumweb-black.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-black.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-blackitalic.eot');
    src:
        url('../../fonts/wigrumweb-blackitalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-blackitalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-bold.eot');
    src:
        url('../../fonts/wigrumweb-bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-bolditalic.eot');
    src:
        url('../../fonts/wigrumweb-bolditalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-bolditalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-medium.eot');
    src:
        url('../../fonts/wigrumweb-medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-mediumitalic.eot');
    src:
        url('../../fonts/wigrumweb-mediumitalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-regular.eot');
    src:
        url('../../fonts/wigrumweb-regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-italic.eot');
    src:
        url('../../fonts/wigrumweb-italic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-light.eot');
    src:
        url('../../fonts/wigrumweb-light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-lightitalic.eot');
    src:
        url('../../fonts/wigrumweb-lightitalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-extralight.eot');
    src:
        url('../../fonts/wigrumweb-extralight.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-extralightitalic.eot');
    src:
        url('../../fonts/wigrumweb-extralightitalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-extralightitalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-thin.eot');
    src:
        url('../../fonts/wigrumweb-thin.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'wigrum';
    src: url('../../fonts/wigrumweb-thinitalic.eot');
    src:
        url('../../fonts/wigrumweb-thinitalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wigrumweb-thinitalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-BlackItalic-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-BlackItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-BlackItalic-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-BlackItalic-Web.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-Black-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-Black-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-Black-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-Black-Web.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-ExtraboldItalic-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-ExtraboldItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-ExtraboldItalic-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-ExtraboldItalic-Web.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-Extrabold-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-Extrabold-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-Extrabold-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-Extrabold-Web.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-BoldItalic-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-BoldItalic-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-BoldItalic-Web.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-Bold-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-Bold-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-Bold-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-Bold-Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-MediumItalic-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-MediumItalic-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-MediumItalic-Web.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-Medium-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-Medium-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-Medium-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-Medium-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-Italic-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-Italic-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-Italic-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-Italic-Web.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-Roman-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-Roman-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-Roman-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-Roman-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-LightItalic-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-LightItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-LightItalic-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-LightItalic-Web.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Publico Headline Web';
    src: url('../../fonts/PublicoHeadline-Light-Web.eot');
    src:
        url('../../fonts/PublicoHeadline-Light-Web.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PublicoHeadline-Light-Web.woff2') format('woff2'),
        url('../../fonts/PublicoHeadline-Light-Web.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'ACaslonPro';
    src: url('../../fonts/ACaslonPro-Regular.woff2');
    src: url('../../fonts/ACaslonPro-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

$ff-publico: 'Publico Headline Web', serif, 'Times New Roman';
$ff-wigrum: 'wigrum', 'Avant Garde', 'Century Gothic', 'Trebuchet MS', helvetica, tahoma;

/* Font families */
$ff-serif: $ff-wigrum;
$ff-sans-serif: $ff-wigrum;
$ff-monospace: $ff-wigrum;

/* Font sizes */
$fs: 1.5rem;
$fs--small: (
    xs: 1.1rem,
    sm: 1.2rem
);
$fs--medium: (
    xs: 1.5rem,
    sm:1.7rem
);
$fs--p: (
    xs: 1.7rem,
    sm: 2.1rem
);
$fs--h1: 3.5rem;
$fs--h2: 2.5rem;
$fs--h3: (
    xs: 1.7rem,
    sm: 1.9rem
);
$fs--label: 1.4rem;
$fs--help: 1.4rem;
$fs--tag: 1.1rem;
$fs--slogan: 1.9rem;
$fs--article-header-title: (
    xs: 2.9rem,
    sm: 5rem
);
$fs--article-header-lead: (
    xs: 2rem,
    sm: 2.5rem
);
$fs--article-header-meta: $fs--small;
$fs--teaser-label: $fs--small;
$fs--teaser-day: (
    xs: 1.5rem,
    sm: 1.9rem
);
$fs--teaser-month: (
    xs: 0.9rem,
    sm: 1.2rem
);
$fs--teaser-title: (
    xs: 1.8rem,
    sm: 2.5rem
);
$fs--user-description: $fs--small;
$fs--caption: $fs--small;
$fs--quote-quote: (
    xs: 1.4rem,
    sm: 1.8rem
);
$fs--quote-author: $fs--small;
$fs--first-letter: (
    xs: 5rem,
    sm: 7rem
);
$fs--comment-author: $fs--medium;
$fs--comment-date: $fs--small;
$fs--highlight: 1.9rem;
$fs--whitepaper-link-title: (
    xs: 1.8rem,
    sm: 1.9rem
);
$fs--whitepaper-link-number: (
    xs: 2rem,
    sm: 3rem
);
$fs--vacancy-link-title: (
    xs: $fs,
    sm: 1.6rem
);
$fs--dossier-link: (
    xs: 1.5rem,
    sm: 1.9rem
);
$fs--pagination: (
    xs: 1.4rem,
    sm:1.5rem
);
$fs--form-help: (
    xs: 1.4rem,
    sm: 1.5rem
);
$fs--cross-link-title: $fs--medium;
$fs--page-header-title: (
    xs: 2.1rem,
    sm: 3rem
);

/* Line heights */
$lh: 2rem;
$lh--small: (
    xs: 1.5rem,
    sm:2rem
);
$lh--medium: (
    xs: 2.5rem,
    sm: 3rem
);
$lh--label: 1.5rem;
$lh--form: 1.8rem;
$lh--p: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--h1: 4.2rem;
$lh--h2: 3.2rem;
$lh--h3: $lh--medium;
$lh--article-header-title: (
    xs: 2.9rem,
    sm: 5rem
);
$lh--article-header-lead: (
    xs: 2.5rem,
    sm: 2.5rem
);
$lh--article-header-meta: $lh--small;
$lh--teaser-label: $lh--small;
$lh--teaser-title: (
    xs: 2rem,
    sm: 3rem
);
$lh--user-description: $lh--small;
$lh--caption: $lh--small;
$lh--quote-quote: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--quote-author: $lh--small;
$lh--first-letter: (
    xs: 5rem,
    sm: 7rem
);
$lh--comment-author: 3rem;
$lh--comment-date: $lh--small;
$lh--highlight: 2.5rem;
$lh--whitepaper-link-title: (
    xs: 2rem,
    sm: 2.5rem
);
$lh--whitepaper-link-number: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--vacancy-link-title: $lh--medium;
$lh--dossier-link: (
    xs: 2rem,
    sm: 2.5rem
);
$lh--form-help: (
    xs: 1.7rem,
    sm: 1.8rem
);
$lh--cross-link-title: $lh--medium;
$lh--page-header-title: (
    xs: 3rem,
    sm: 4rem
);

/* Letter spacings */
$ls--label: 1px;
